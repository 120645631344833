<template>
    <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">

<div id="page-content">
  <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        My Schedule
       </h4>
      </div>
  
      <div class="row" v-if="isShow">
        <div class="col-lg-12" >
          <div class="widjet" v-if="timeTableRecord.tables.length > 0">
          
  
            <div class="widjetcontent" >
              <div class="restable" style="margin: 7px;width: 99%;">
                <div class="resrow resheader">
                  <div class="cell" style="text-align: center;font-weight: bold;
    font-size: medium;">Day</div>
                  <div class="cell" style="text-align: center;" :key="cIndex" v-for="(tc, cIndex) in timeTableRecord.tables">
                    <!-- <span> -->
                    <span style="font-weight: bold;
    font-size: medium;" v-if="(subjectGroup[ tc['mon'] ] && checkClassAndClassRoom(tc['mon'],tc.classId,tc.classroomId)) || 
                                (subjectGroup[ tc['tue'] ] && checkClassAndClassRoom(tc['tue'],tc.classId,tc.classroomId)) || 
                                (subjectGroup[ tc['wed'] ] && checkClassAndClassRoom(tc['wed'],tc.classId,tc.classroomId)) ||
                                (subjectGroup[ tc['thur'] ] && checkClassAndClassRoom(tc['thur'],tc.classId,tc.classroomId)) ||
                                (subjectGroup[ tc['fri'] ] && checkClassAndClassRoom(tc['fri'],tc.classId,tc.classroomId)) ||
                                (subjectGroup[ tc['sat'] ] && checkClassAndClassRoom(tc['sat'],tc.classId,tc.classroomId)) ||
                                (subjectGroup[ tc['sun'] ] && checkClassAndClassRoom(tc['sun'],tc.classId,tc.classroomId)) ">
                      <!-- <span>{{ cIndex + 1 }}</span> -->
                       {{ tc.from }} - {{ tc.to }}
                    </span>
                  </div>
  
                  
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="color: purple;font-size: large;text-align: center;">
                    Monday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Monday" :key="mIndex" v-for="(m, mIndex) in timeTableRecord.tables">
                    <span v-if="!isEdit" style="text-align: center;">{{ m['mon'] && checkClassAndClassRoom(m['mon'],m.classId,m.classroomId) ? subjectGroup[ m['mon'] ]  : '' }}</span><br>
                    <span v-if="m['mon'] && subjectGroup[ m['mon'] ] && checkClassAndClassRoom(m['mon'],m.classId,m.classroomId)" style="text-align: center;color: brown;">{{ m.class }} | {{ m.classroom }}</span>

                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Tuesday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Tuesday" :key="tIndex" v-for="(t, tIndex) in timeTableRecord.tables">
                    <!-- <span v-if="!isEdit">{{ t['tue'] ? subjectGroup[ t['tue'] ] : '' }}</span> -->
                    
                    <span v-if="!isEdit" style="text-align: center;">{{ t['tue'] && checkClassAndClassRoom(t['tue'],t.classId,t.classroomId) ? subjectGroup[ t['tue'] ] : '' }}</span><br>
                    <span v-if="t['tue'] && subjectGroup[ t['tue'] ] && checkClassAndClassRoom(t['tue'],t.classId,t.classroomId)" style="text-align: center;color: brown;">{{ t.class }} | {{ t.classroom }}</span>
                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Wednesday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Wednesday" :key="wIndex" v-for="(w, wIndex) in timeTableRecord.tables">
                    <!-- <span v-if="!isEdit">{{ w['wed'] ? subjectGroup[ w['wed'] ] : ''}}</span> -->
                    
                    <span v-if="!isEdit" style="text-align: center;">{{ w['wed'] && checkClassAndClassRoom(w['wed'],w.classId,w.classroomId) ? subjectGroup[ w['wed'] ]  : '' }}</span><br>
                    <span v-if="w['wed'] && subjectGroup[ w['wed'] ] && checkClassAndClassRoom(w['wed'],w.classId,w.classroomId)" style="text-align: center;color: brown;">{{ w.class }} | {{ w.classroom }}</span>
                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Thursday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Thursday" :key="thIndex" v-for="(th, thIndex) in timeTableRecord.tables">
                    <!-- <span v-if="!isEdit">{{ th['thur'] ? subjectGroup[ th['thur'] ] : '' }}</span> -->
                    

                    <span v-if="!isEdit" style="text-align: center;">{{ th['thur'] && checkClassAndClassRoom(th['thur'],th.classId,th.classroomId) ? subjectGroup[ th['thur'] ] : '' }}</span><br>
                    <span v-if="th['thur'] && subjectGroup[ th['thur'] ] && checkClassAndClassRoom(th['thur'],th.classId,th.classroomId)" style="text-align: center;color: brown;">{{ th.class }} | {{ th.classroom }}</span>
                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Friday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Friday" :key="friIndex" v-for="(f, friIndex) in timeTableRecord.tables">
                    <!-- <span v-if="!isEdit">{{ f['fri'] ? subjectGroup[ f['fri'] ] : ''}}</span> -->
                    

                    <span v-if="!isEdit" style="text-align: center;">{{ f['fri'] && checkClassAndClassRoom(f['fri'],f.classId,f.classroomId) ? subjectGroup[ f['fri'] ] : '' }}</span><br>
                    <span v-if="f['fri'] && subjectGroup[ f['fri'] ] && checkClassAndClassRoom(f['fri'],f.classId,f.classroomId)" style="text-align: center;color: brown;">{{ f.class }} | {{ f.classroom }}</span>
                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Saturday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Saturday" :key="satIndex" v-for="(s, satIndex) in timeTableRecord.tables">
                    <span v-if="!isEdit">{{ s['sat'] && checkClassAndClassRoom(s['sat'],s.classId,s.classroomId) ? subjectGroup[ s['sat'] ] : ''}}</span><br>
                    
                    <!-- <span v-if="!isEdit" style="text-align: center;">{{ s['mon'] ? subjectGroup[ s['mon'] ] : '' }}</span><br> -->
                   
                    <span v-if="s['sat'] && subjectGroup[ s['sat'] ] && checkClassAndClassRoom(s['sat'],s.classId,s.classroomId)" style="text-align: center;color: brown;">{{ s.class }} | {{ s.classroom }}</span>
                  </div>
                </div>
  
                <div class="resrow">
                  <div class="cell" data-title="Day" style="text-align: center;color: purple;font-size: large;">
                    Sunday
                  </div>
                  <div class="cell" style="text-align: center;" data-title="Sunday" :key="sunIndex" v-for="(su, sunIndex) in timeTableRecord.tables">
                    <!-- <span v-if="!isEdit">{{ su['sun'] ? subjectGroup[ su['sun'] ] : ''}}</span> -->
                   

                    <span v-if="!isEdit" style="text-align: center;">{{ su['sun'] && checkClassAndClassRoom(su['sun'],su.classId,su.classroomId) ? subjectGroup[ su['sun'] ] : '' }}</span><br>
                    <span v-if="su['sun'] && subjectGroup[ su['sun'] ] && checkClassAndClassRoom(su['sun'],su.classId,su.classroomId)" style="text-align: center;color: brown;">{{ su.class }} | {{ su.classroom }}</span>
                  </div>
                </div>
              </div>
  
              
            </div>
            
           
  
          
          </div>
          <div class="widjet" v-else style="height: 235px;margin-top: 10px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" v-if="!loader">...Fetching...</span>
            <span style="margin-left: 42%;" v-else>Nothing Scheduled </span>
          </div>
        </div>
        </div>
        
        </div>
        <div class="row" v-if="!isShow">
        <div class="col-lg-12" >
         
          <div class="widjet"  style="height: 235px;margin-top: 10px;">
          <div style="padding-top: 92px;" >
            <span style="margin-left: 42%;" >Nothing Scheduled </span>
          </div>
        </div>
        </div>
        
        </div>
       
       
     
  
     
    </div>
</div>
  </template>
  
  <script>
  import "vue-select/dist/vue-select.css";

  import secureUI from "../../utils/secureUI";
  import ViService from "@/services/ViService";
  
  export default {
    name: "teacher",
    // components: {
    //   'vi-spinner' : () => import('../../Common/ViSpinner')
    // },
    data() {
      return {
        userDetail: {},
        loader: false,
        isShow: false,
        isEdit: false,
        subjects: [],
        assignList: [],
        timeTableRecord: {
          days: "",
          class: "",
          classroom: "",
          tables: []
        }
       
      };
    },
    props: {
      classId: {
        type: String,
      },
      classRoom: {
        type: String,
      }
    },
    watch: {
      classId: function (currentVal, oldVal) {
        let hasCurrentParam = currentVal || "";
        let hasOldParam = oldVal || "";
        if (hasCurrentParam != hasOldParam) {
          this.getTimeTableInfo();
        }
      },
      classRoom: function (currentVal, oldVal) {
        let hasCurrentParam = currentVal || "";
        let hasOldParam = oldVal || "";
        if (hasCurrentParam != hasOldParam) {
          this.getTimeTableInfo();
        }
      },
    },
    created() {
     this.initComponent()
      this.getTimeTableInfo(); 
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
      subjectGroup() {
        return this.subjects.reduce((acc, a) => {
          acc[a._id] = a.subjectName;
          return acc;
        }, {});
      }
    },
    methods: {

      previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",data);
     this.$router.go(-1)
    },
      checkClassAndClassRoom(subjectId,classId,classRoomId){
        let userData = secureUI.sessionGet("user");

        let check = this.assignList.find( x => x.class._id == classId && x._id == classRoomId)


        if(check){
          
         // let checkSubject = check.assignedTeachers.filter( x => x.subjectId == subjectId)
         let checkSubject = check.assignedTeachers.filter( x => x.subjectId == subjectId && (x.teacherId && x.teacherId ==  userData.userDetails._id))
          
          if(checkSubject.length > 0){
            return true
          }else{
            return false
          }
        }else{
          return false
        }

      },
     
     
      initComponent() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          
          this.userDetail = userData.userDetails;
          let currentBranch = localStorage.getItem('currentBranch')

          if(currentBranch == this.userDetail.branch._id){
            this.isShow = true
          }
         

        }
      },
      async getTimeTableInfo() {
        let userData = secureUI.sessionGet("user");
  
        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          let requestBody = {
            view: 'teacher'
          };
  
          const response = await ViService.viXPost(
            "/academic/getMySchedule",
            requestBody,
            userData.token
          );

          if (response.isSuccess && response.data) {
            const resultData = secureUI.secureGet(response.data);
            
            // if( resultData.timeTable && resultData.timeTable._id ) {
              this.timeTableRecord.tables = resultData.timeTable;
            // }
  
            this.subjects = [ ...resultData.subjects ];
            this.assignList = resultData.assignSubjectList;

          } else {
            this.$toasted.error(response.message);
          }
          this.loader = true
        }
      },
    
     
     padTo2Digits(num) {
        return String(num).padStart(2, '0');
      },
    
      
     
    },
  };
  </script>
  
  <style scoped>
  .link-color {
    color:dodgerblue;
  }
  </style>
  